exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-dance-company-js": () => import("./../../../src/pages/about/dance-company.js" /* webpackChunkName: "component---src-pages-about-dance-company-js" */),
  "component---src-pages-about-kathak-js": () => import("./../../../src/pages/about/kathak.js" /* webpackChunkName: "component---src-pages-about-kathak-js" */),
  "component---src-pages-about-rsdc-js": () => import("./../../../src/pages/about/rsdc.js" /* webpackChunkName: "component---src-pages-about-rsdc-js" */),
  "component---src-pages-anarkali-cast-js": () => import("./../../../src/pages/anarkali/cast.js" /* webpackChunkName: "component---src-pages-anarkali-cast-js" */),
  "component---src-pages-anarkali-js": () => import("./../../../src/pages/anarkali.js" /* webpackChunkName: "component---src-pages-anarkali-js" */),
  "component---src-pages-anarkali-production-js": () => import("./../../../src/pages/anarkali/production.js" /* webpackChunkName: "component---src-pages-anarkali-production-js" */),
  "component---src-pages-anarkali-story-js": () => import("./../../../src/pages/anarkali/story.js" /* webpackChunkName: "component---src-pages-anarkali-story-js" */),
  "component---src-pages-anarkali-team-js": () => import("./../../../src/pages/anarkali/team.js" /* webpackChunkName: "component---src-pages-anarkali-team-js" */),
  "component---src-pages-classes-info-js": () => import("./../../../src/pages/classes/info.js" /* webpackChunkName: "component---src-pages-classes-info-js" */),
  "component---src-pages-classes-js": () => import("./../../../src/pages/classes.js" /* webpackChunkName: "component---src-pages-classes-js" */),
  "component---src-pages-classes-waitlist-js": () => import("./../../../src/pages/classes/waitlist.js" /* webpackChunkName: "component---src-pages-classes-waitlist-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-enrolment-js": () => import("./../../../src/pages/enrolment.js" /* webpackChunkName: "component---src-pages-enrolment-js" */),
  "component---src-pages-gallery-photos-js": () => import("./../../../src/pages/gallery/photos.js" /* webpackChunkName: "component---src-pages-gallery-photos-js" */),
  "component---src-pages-gallery-videos-js": () => import("./../../../src/pages/gallery/videos.js" /* webpackChunkName: "component---src-pages-gallery-videos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-performances-js": () => import("./../../../src/pages/performances.js" /* webpackChunkName: "component---src-pages-performances-js" */),
  "component---src-pages-secure-data-js": () => import("./../../../src/pages/secure/data.js" /* webpackChunkName: "component---src-pages-secure-data-js" */),
  "component---src-pages-voucher-js": () => import("./../../../src/pages/voucher.js" /* webpackChunkName: "component---src-pages-voucher-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

